import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "v0--uniswap-is-born",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#v0--uniswap-is-born",
        "aria-label": "v0  uniswap is born permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`V0 — Uniswap is Born`}</h1>
    <p>{`On November 2, 2018, Uniswap was publicly announced and deployed to the Ethereum mainnet. In an exciting, anxiety-inducing moment I fired off the announcement tweets to my ~200 followers.`}</p>
    <p><blockquote parentName="p" {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`1/🦄 Excited to announce the launch of `}<a parentName="p" {...{
            "href": "https://twitter.com/UniswapExchange"
          }}>{`@UniswapExchange`}</a>{` ! It's a protocol for automated exchange of ERC20 tokens on Ethereum. `}<a parentName="p" {...{
            "href": "https://t.co/czTqyRit7u"
          }}>{`https://t.co/czTqyRit7u`}</a></p>{`— Hayden Adams 🦄 (@haydenzadams) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/haydenzadams/status/1058376395108376577"
        }}>{`November 2, 2018`}</a></blockquote></p>
    <p>{`For many people, this was the first they heard of the project. For me it was the culmination of over a years worth of work — and a huge amount of help along the way.`}</p>
    <p>{`Uniswap changed my life and today is its birthday. To celebrate this, I want to tell its story from the beginning in V0 of the Uniswap Birthday Blog series.`}</p>
    <p>{`Stay tuned for V1 and V2 where I discuss Uniswap’s first year on Ethereum mainet and our plans for its future.`}</p>
    <hr></hr>
    <h1 {...{
      "id": "accepting-ethereum",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#accepting-ethereum",
        "aria-label": "accepting ethereum permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Accepting Ethereum`}</h1>
    <p>{`On July 6th, 2017 I was laid off from my first job out of college, working as a Mechanical Engineer at Siemens. Feeling down and directionless I informed my friend `}<a parentName="p" {...{
        "href": "https://twitter.com/karl_dot_tech"
      }}>{`Karl Floersch`}</a>{`, who at the time was working on Casper FFG at the Ethereum Foundation. The conversation went something like this:`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Hayden`}</strong>{`: I just got laid off :(`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Karl`}</strong>{`: Congratulations, this is the best thing that could have happened to you!!! Mechanical Engineering is a dying field. Ethereum is the future and you’re still early. Your new destiny is to write smart contracts!`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Hayden`}</strong>{`: Don’t I need to like know how to code?`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Karl`}</strong>{`: Not really, coding is easy. Nobody understands how to write smart contracts yet anyway. Ethereum, proof-of-stake, trustless computation, etc.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Hayden`}</strong>{`: Okay…`}</p>
    </blockquote>
    <p>{`Catching me at a low point, Karl convinced me to accept Ethereum into my life. I was determined to give it a shot, and spent the next two months learning the basics of Ethereum, Solidity, and JavaScript.`}</p>
    <p>{`In order to expand my skillset, I decided it was time to work on a “real” project. At Karl’s suggestion I decided to implement an automated market maker, as described by Vitalik in `}<a parentName="p" {...{
        "href": "https://www.reddit.com/r/ethereum/comments/55m04x/lets_run_onchain_decentralized_exchanges_the_way/"
      }}>{`this reddit post`}</a>{` and `}<a parentName="p" {...{
        "href": "https://vitalik.ca/general/2017/06/22/marketmakers.html"
      }}>{`this blogpost`}</a>{`.`}</p>
    <h1 {...{
      "id": "proof-of-something",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#proof-of-something",
        "aria-label": "proof of something permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Proof-of-Something`}</h1>
    <p>{`From October to November I built a proof-of-concept, including a smart contract and my very first website. The contract had a single liquidity provider and allowed simple swaps. `}<a parentName="p" {...{
        "href": "https://haydenadams.github.io/uniswap-retro/"
      }}>{`Here it is`}</a>{` in all its glory:`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/6672/1*JgLez7l8v6rh5BObvMSzKw.png",
        "alt": "[https://haydenadams.github.io/uniswap-retro/](https://haydenadams.github.io/uniswap-retro/)"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote">{`Disclosure — The demo remains unchanged from its original version, except for a single line of code `}<inlineCode parentName="p">{`window.ethereum.enable()`}</inlineCode>{` that I added to make it work with 2019 MetaMask.`}</p>
    </blockquote>
    <p>{`Building the POC was my first step down the rabbit-hole of programmable money. It was the most intriguing thing I had worked on in my life. It didn’t even feel like work.`}</p>
    <p>{`I also began to see the UX implications of automated market makers. At the time, EtherDelta was the only decentralized exchange with traction, but the UX felt messy and unintuitive. Using my demo somehow felt better.`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/2730/1*RZHrueofS4UrqohzdL5Obg.png",
        "alt": "EtherDelta.com — October, 2017"
      }}></img></p>
    <h1 {...{
      "id": "devcon-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#devcon-3",
        "aria-label": "devcon 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Devcon 3`}</h1>
    <p>{`In a talk at Devcon 3, Karl used my Uniswap demo as an example of the power of crypto-economics and open-source financial applications on Ethereum.`}</p>
    <p><iframe parentName="p" {...{
        "width": "100%",
        "height": 315,
        "src": "https://www.youtube-nocookie.com/embed/-alrVUv6E24?rel=0&feature=emb_title",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true
      }}></iframe></p>
    <p>{`I had been unemployed for five months, living off cryptocurrency I was fortunate enough to have bought earlier in the year. Uniswap was a success by my only metric at the time — I knew how to write smart contracts. Maybe it was time to move on and get a job (if there were any jobs for Solidity-native developers).`}</p>
    <p>{`However an attendee of Karl’s Devcon talk — `}<a parentName="p" {...{
        "href": "https://twitter.com/PascalVanHecke"
      }}>{`Pascal Van Hecke `}</a>{`— reached out to me. He had been closely following the progress of automated market makers on Ethereum and expressed interest in supporting my efforts. He even gave a grant to fund my next month of research. We began weekly calls to discuss progress.`}</p>
    <p>{`Pascal brought new ideas to the table. More importantly, however, our calls brought a sense of structure and accountability to my work. Uniswap had two major unsolved problems which I set out to tackle with an engineering mindset:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`It only worked for a single ETH/ERC20 pair`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`It only worked for a single liquidity provider`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "ethereum-values",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ethereum-values",
        "aria-label": "ethereum values permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ethereum Values`}</h1>
    <p>{`By this point I was fully captivated by Ethereum’s unbounded potential. These were the properties I cared about:`}</p>
    <h3 {...{
      "id": "it-was-censorship-resistant-no-one-could-stop-it",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#it-was-censorship-resistant-no-one-could-stop-it",
        "aria-label": "it was censorship resistant no one could stop it permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`It was `}<strong parentName="h3">{`censorship resistant`}</strong>{`. No one could stop it.`}</h3>
    <h3 {...{
      "id": "it-was-decentralized-no-one-controlled-it",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#it-was-decentralized-no-one-controlled-it",
        "aria-label": "it was decentralized no one controlled it permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`It was decentralized. No one controlled it.`}</h3>
    <h3 {...{
      "id": "it-was-permissionless-anyone-could-use-it",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#it-was-permissionless-anyone-could-use-it",
        "aria-label": "it was permissionless anyone could use it permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`It was permissionless. Anyone could use it.`}</h3>
    <h3 {...{
      "id": "it-was-secure-anyone-could-verify-execution",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#it-was-secure-anyone-could-verify-execution",
        "aria-label": "it was secure anyone could verify execution permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`It was secure. Anyone could verify execution.`}</h3>
    <p>{`Yet something felt off in the ether. The major projects on Ethereum embodied some of its properties, but few embraced them fully. Central points of failure, censorable applications, and overly complex architecture. DAPPs were designed entirely around the idea of having a token for use cases that clearly did not need one.`}</p>
    <p>{`I began thinking about Uniswap not just as a learning tool for myself — but one for others. I could not imagine a world where it competed with “real” projects. You know — the ones that raised between $20,000,000 and $150,000,000 in Summer 2017. But maybe it could serve as an example of an application that truly embodied Ethereum.`}</p>
    <h1 {...{
      "id": "nyc-mesh",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#nyc-mesh",
        "aria-label": "nyc mesh permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NYC Mesh`}</h1>
    <p>{`In December, I had attended an `}<a parentName="p" {...{
        "href": "https://www.nycmesh.net/"
      }}>{`NYC Mesh`}</a>{` meetup with Karl, following `}<a parentName="p" {...{
        "href": "https://www.nycmesh.net/blog/meshcoin/"
      }}>{`an anti-crypto article`}</a>{` from its founder. Now Ethereum famous — Karl was recognized by a Coindesk reporter who asked him about the intersection between meshnets and crypto. Karl did not want to give an interview so he directed the reporter to me. I blathered on about Layer 2 solutions I barely understood, and was quoted in `}<a parentName="p" {...{
        "href": "https://www.coindesk.com/plan-b-ethereum-innovators-reviving-fight-net-neutrality"
      }}>{`an article`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.coindesk.com/plan-b-ethereum-innovators-reviving-fight-net-neutrality"
        }}><strong parentName="a">{`Plan B? Ethereum Innovators Are Reviving the Fight for Net Neutrality - CoinDesk`}</strong></a></p>
      <small>A tech meetup at a dimly lit New York City bar - so far, nothing out of the ordinary. But what's peculiar about this particular meetup is that other locations throughout the East Village are being connected to the bar's Wi-Fi-enabled node, allowing anyone in the area to not only piggyback off all the signals but visit websites only accessible to others on the network.</small>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/_callil"
      }}>{`Callil Capuozzo`}</a>{`, a friend from from elementary school through high school who I had not seen in ~6 years, saw the article and reached out. Since then he had worked as a designer at Microsoft, Google, and more recently had delved into Ethereum while working on a website for `}<a parentName="p" {...{
        "href": "https://www.foam.space/"
      }}>{`FOAM`}</a>{`.`}</p>
    <h1 {...{
      "id": "uniswap-pre-alpha",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#uniswap-pre-alpha",
        "aria-label": "uniswap pre alpha permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Uniswap Pre-alpha`}</h1>
    <p>{`By late January 2018, all major smart contract issues had been solved. Exchange contracts could support multiple liquidity providers by using an internal liquidity token to track each LP’s share of generated fees and the underlying collateral. A factory contract allowed anyone to add support for a token. All tokens were paired with ETH allowing it to be used as an intermediary for anything-to-anything swaps in a single transaction.`}</p>
    <p>{`I reconnected with Callil just after finishing the updated Uniswap smart contracts, and we discussed the project at length. He offered to help out with the frontend — which was now lagging behind the contracts. Working around his other projects, Callil designed and built a sleek new interface for Uniswap on top of my incredibly shitty React codebase.`}</p>
    <p>{`Several weeks in it was pretty clear my react code was untenably bad. Fortunately, I had recently reconnected with a friend from college, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/uciel-vilchis/"
      }}>{`Uciel Vilchis`}</a>{`. Uciel was just coming out of a coding bootcamp (that he entered on the advice of our mutual Karl Floersch).`}</p>
    <h3 {...{
      "id": "you-should-learn-to-code--karl-floersch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#you-should-learn-to-code--karl-floersch",
        "aria-label": "you should learn to code  karl floersch permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`You should learn to code — Karl Floersch`}</h3>
    <p>{`Looking to build up his resume and experience, Uciel agreed to refactor the Uniswap frontend codebase.`}</p>
    <p>{`By March, 2018, the three of us had built a fully featured demo of Uniswap. Yes, of course I dug it up and hosted it. `}<a parentName="p" {...{
        "href": "https://haydenadams.github.io/uniswap-prealpha/"
      }}>{`Here it is`}</a>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/5184/1*Dv5Kovp7-Xlu-puNO6eLuQ.png",
        "alt": "[https://haydenadams.github.io/uniswap-prealpha/](https://haydenadams.github.io/uniswap-prealpha/)"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote">{`Disclosure — The demo remains unchanged from its original version, except for a single line of code `}<inlineCode parentName="p">{`*window.ethereum.enable()*`}</inlineCode>{` that I added to make it work with 2019 MetaMask.`}</p>
    </blockquote>
    <h1 {...{
      "id": "meeting-vitalik",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#meeting-vitalik",
        "aria-label": "meeting vitalik permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Meeting Vitalik`}</h1>
    <p>{`By April 2018 I had been unemployed for 10 months. The crypto I was living off of was down over 75%, dramatically decreasing my personal runway. Despite this, I yolo-bought a last minute flight to Seoul, South Korea. This was the first time I left North America in my 24 years of life. Partially a personal trip, it was timed to coincide with `}<a parentName="p" {...{
        "href": "https://deconomy.com/deconomy-2018-recap/"
      }}>{`Deconomy 2018`}</a>{`.`}</p>
    <p>{`I attempted to enter the conference without a ticket, but was firmly rejected. However, Karl was just arriving along with other members of the Ethereum foundation. Karl pulled me to the side, and introduced me to Vitalik, who he had already discussed Uniswap with in the past. Our conversation went something like this:`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Karl`}</strong>{`: This is my friend Hayden, he made Uniswap!`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Vitalik`}</strong>{`: Hi nice to meet you! Is it open source?`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Me`}</strong>{`: Of course!`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Vitalik`}</strong>{`: Whats the URL?`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Me`}</strong>{`: `}<a parentName="p" {...{
          "href": "https://github.com/haydenadams/uniswap"
        }}>{`https://github.com/haydenadams/uniswap`}</a></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Vitalik`}</strong>{`: `}<em parentName="p">{`reads my entire smart contract on his phone`}</em></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Vitalik`}</strong>{`: Have you considered writing it in Vyper? Also, you should apply for an Ethereum foundation grant.`}</p>
    </blockquote>
    <p>{`Vitalik’s ideas had yet to fail me. Immediately after returning from Seoul, I spent two weeks re-writing the contracts in Vyper. There were no guides or developer tools like Solidity had, but I was able to use `}<a parentName="p" {...{
        "href": "https://github.com/ethereum/casper/blob/master/casper/contracts/simple_casper.v.py"
      }}>{`the original Casper FFG`}</a>{` contract as a reference. At the time, this was the only Vyper contract I could find outside of simple examples in the core Vyper repo.`}</p>
    <h1 {...{
      "id": "making-some-friends",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#making-some-friends",
        "aria-label": "making some friends permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Making Some Friends`}</h1>
    <p>{`Up to this point Karl was my only close friend in the crypto world, but that was soon going to change. Just before leaving Karl invited me to a small social gathering. There I met and became friends with `}<a parentName="p" {...{
        "href": "https://twitter.com/phildaian"
      }}>{`Philip Daian`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/danrobinson"
      }}>{`Dan Robinson`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://twitter.com/RealZandy"
      }}>{`Andy Milenius`}</a>{`. In addition to being wonderful people, all would become crucial to the success of Uniswap and influence its future direction.`}</p>
    <p>{`Two weeks later, in May, I flew to Toronto for Edcon 2018. This was the first Ethereum conference where I made it past security. I spent 3 days attending talks, meeting people, and demoing Uniswap. My four friends in crypto were all there and introduced me to a ton of other people. My crypto social-network was expanding.`}</p>
    <p><em parentName="p">{`Among the friends I made at Edcon, `}<a parentName="em" {...{
          "href": "https://twitter.com/jinglanW"
        }}>{`Jinglan Wang`}</a>{` deserves a shoutout as she has been a fantastic advisor ever since.`}</em></p>
    <p>{`I met David Knott, a former Vyper dev, in person for the first time. I told him that I had re-written Uniswap in Vyper. The next morning at 8am I woke up to a phone call from him. He told me he was scheduled to give a talk on Vyper including a live demo in two hours. Uniswap was the only DAPP being written in Vyper, and he asked if I was interested in giving the talk instead.`}</p>
    <p>{`I explained that I had only just learned Vyper two weeks prior, I had never spoken in public, and that I generally had no idea what I was doing. David assured me it was fine — two weeks building on Vyper was more than enough to speak publicly and authoritatively on it. So I gave `}<a parentName="p" {...{
        "href": "https://youtu.be/61PWFQpR1YQ?list=PLjB3u3uCYxnxXUXEYIp9V8aSxMyuyVdFg&t=3729"
      }}>{`my first talk`}</a>{`:`}</p>
    <p><iframe parentName="p" {...{
        "width": "100%",
        "height": 315,
        "src": "https://www.youtube-nocookie.com/embed/61PWFQpR1YQ?rel=0&list=PLjB3u3uCYxnxXUXEYIp9V8aSxMyuyVdFg&start=3729",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true
      }}></iframe></p>
    <p>{`I took the same flight back to NY as Dan. We spent the entire time gas-optimizing Uniswap. By the time the plane landed, Uniswap was 30% more efficient. It was now the most gas efficient exchange on Ethereum by a significant margin.`}</p>
    <p>{`Edcon 2018 was extremely encouraging. The reactions to my Uniswap demo were more than just mild interest. People were legitimately excited by it. They seemed to understand and share the values I intended for it to be an example of. I realized Ethereum’s community went far beyond ICOs. There were people who truly cared about its vision of a permissionless, decentralized financial system.`}</p>
    <h1 {...{
      "id": "nyc-blockchain-week-2018",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#nyc-blockchain-week-2018",
        "aria-label": "nyc blockchain week 2018 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NYC Blockchain Week 2018`}</h1>
    <p>{`My plane home from Edcon landed me directly in NYC Blockchain Week. I spent the next week attending an endless spew of events and parties. A tweet made its way onto my feed.`}</p>
    <p><blockquote parentName="p" {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`Are you dreading blockchain week?`}<br parentName="p"></br><br parentName="p"></br>{`Are you waking up sweating at the prospect of being pitched a load of bullshit?`}<br parentName="p"></br><br parentName="p"></br>{`Are you behind on the projects that actually matter to you?`}<br parentName="p"></br><br parentName="p"></br>{`Are you desperate for a place to escape the mania and do some deep work?`}<br parentName="p"></br><br parentName="p"></br>{`Come along to `}<a parentName="p" {...{
            "href": "https://twitter.com/hashtag/BUIDLWEEK?src=hash&ref_src=twsrc%5Etfw"
          }}>{`#BUIDLWEEK`}</a>{` 🎧💻 `}<a parentName="p" {...{
            "href": "https://t.co/v5QSgXfKce"
          }}>{`https://t.co/v5QSgXfKce`}</a></p>{`— Ric Burton 🇬🇧 ‣ 🇺🇸 🦇🔊 (@ricburton) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/ricburton/status/994545185538310144"
        }}>{`May 10, 2018`}</a></blockquote></p>
    <p>{`Living a 15 minute walk away, I decided to check it out.`}</p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/ricburton"
      }}>{`Richard Burton`}</a>{` was the founder of Balance, an Ethereum wallet startup. I showed him my demo, and he immediately wanted to understand how it worked. A long conversation ensued.`}</p>
    <p>{`Up to this point, I considered my role in Uniswap to be mostly a technical one. When someone asked how it worked, I often started by telling them the mathematical formula behind it. Many people walked away confused.`}</p>
    <p>{`Richard helped me understand that people not understanding Uniswap it was a me-problem, not a them-problem. Developers were just a small part of a bigger picture. If I wanted people to use my project, I needed to talk about it on their terms, in ways they understood. Uniswap’s biggest outstanding challenge was a social one.`}</p>
    <h1 {...{
      "id": "things-become-kind-of-real",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#things-become-kind-of-real",
        "aria-label": "things become kind of real permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Things Become Kind of Real`}</h1>
    <p>{`It was around this time that I decided Uniswap was something I wanted to take to completion. I mapped out what was needed for a mainet launch.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Finalized, production-ready smart contracts`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Responsive, user friendly trading interface`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Smart contract security audit`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Finished whitepaper`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Developer docs`}</p>
      </li>
    </ol>
    <p>{`My previously mentioned friend Phil is a blockchain security expert. On his advice (and with his introduction) I received a quote from `}<a parentName="p" {...{
        "href": "https://runtimeverification.com/"
      }}>{`Runtime Verification`}</a>{` for a formalized model of Uniswap, a high level code review, and a partial formal verification of the smart contracts. Taking all this into consideration, I applied for an Ethereum Foundation grant of `}{`$`}{`50,000 + the quoted cost from RV.`}</p>
    <h1 {...{
      "id": "balance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#balance",
        "aria-label": "balance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Balance`}</h1>
    <p>{`Summer 2018 kicked off with me obsessively refactoring Uniswap’s smart contracts, working on the whitepaper, and attending crypto events in my spare time. For the next two months, I interviewed and then waited to hear back about the grant.`}</p>
    <p>{`I worked daily at the Balance office, which Richard and the rest of the team members at Balance — `}<a parentName="p" {...{
        "href": "https://twitter.com/chrisbaroni"
      }}>{`Christian Baroni`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/jinrummie"
      }}>{`Jin Ching`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://twitter.com/mikedemarais"
      }}>{`Mike Demerais`}</a>{` were kind enough to let me use. Knowing I was running low on funds, Richard was even generous enough to give me a personal grant to support my efforts.`}</p>
    <p>{`I began attending weekly meetups called Whiteboard Wednesday at the Balance office. Every Wednesday for 2 hours anyone and everyone working on a crypto project was invited to share their weekly progress.`}</p>
    <p><blockquote parentName="p" {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`The `}<a parentName="p" {...{
            "href": "https://twitter.com/Balance_io"
          }}>{`@Balance_io`}</a>{` Whiteboard Wednesday is kicking off with `}<a parentName="p" {...{
            "href": "https://twitter.com/karl_dot_tech"
          }}>{`@karl_dot_tech`}</a>{`. Enjoy! `}<a parentName="p" {...{
            "href": "https://t.co/nqNDPAm2nX"
          }}>{`https://t.co/nqNDPAm2nX`}</a></p>{`— Ric Burton 🇬🇧 ‣ 🇺🇸 🦇🔊 (@ricburton) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/ricburton/status/1019708830672797696"
        }}>{`July 18, 2018`}</a></blockquote></p>
    <p>{`I attended almost every week, explaining and re-explaining Uniswap to different groups of people. I learned how to speak about Uniswap in different settings, and soon could explain it to almost anyone. I even started dipping my toes into crypto twitter.`}</p>
    <p>{`Similar to my calls with Pascal, Whiteboard Wednesday brought a degree of accountability to my life. I did not want to show up two weeks in a row without progress to report.`}</p>
    <h1 {...{
      "id": "maker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#maker",
        "aria-label": "maker permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Maker`}</h1>
    <p>{`As Summer progressed, I spent an increasing amount of time at the NYC `}<a parentName="p" {...{
        "href": "https://makerdao.com/"
      }}>{`MakerDAO`}</a>{` office where my previously mentioned friend Andy was CTO. Maker is a decentralized stablecoin project that everyone reading this article already knows about so why am I even describing it. I became friends with a number of people at Maker including `}<a parentName="p" {...{
        "href": "https://twitter.com/ashleighschap"
      }}>{`Ashleigh Schapp`}</a>{` — another friend who would become important to Uniswap.`}</p>
    <p>{`Working at both the Balance and Maker offices provided me with a constant stream of interesting people to meet and projects to learn about. Summer passed by quickly. My smart contracts were as optimized as I could make them on my own. Finally, towards the end of July I received an email. `}<a parentName="p" {...{
        "href": "https://blog.ethereum.org/2018/08/17/ethereum-foundation-grants-update-wave-3/"
      }}>{`Uniswap would receive a grant`}</a>{` from the Ethereum Foundation!`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://blog.ethereum.org/2018/08/17/ethereum-foundation-grants-update-wave-3/"
        }}><strong parentName="a">{`Ethereum Foundation Grants Update - Wave III`}</strong></a></p>
      <small>Ethereum Foundation Grants Update We've been hard at work getting to know so many amazing people and projects, and are extremely excited to announce the recipients of the Wave III of the Ethereum Foundation Grants Program! We kicked off 2018 with a blog post to galvanize scalability research for first...</small>
    </blockquote>
    <h1 {...{
      "id": "formalized-model",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#formalized-model",
        "aria-label": "formalized model permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Formalized Model`}</h1>
    <p>{`Grant in hand, I immediately contracted Runtime Verification to formalize and audit the contracts. In a series of calls with `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/daejun-park-27a89a6/"
      }}>{`Daejun Park`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/yzhang90"
      }}>{`Yi Zhang`}</a>{`, and Xiaohong Cheng of RV, I detailed Uniswap’s mechanism and most important properties.`}</p>
    <p>{`They began by creating a formalized model of Uniswap. Next, they they created a code spec, which re-worked math operations to minimize rounding error, and always favor liquidity providers over traders. The results of this work `}<a parentName="p" {...{
        "href": "https://github.com/runtimeverification/verified-smart-contracts/blob/uniswap/uniswap/x-y-k.pdf"
      }}>{`can be found here`}</a>{`.`}</p>
    <p>{`I modified the contracts to fit the spec. Next, RV performed a high level code review in which they recommended a number of a safety checks, fixes related to consistency, and other minor changes. A list of these improvements `}<a parentName="p" {...{
        "href": "https://github.com/runtimeverification/verified-smart-contracts/blob/uniswap/uniswap/issues.md"
      }}>{`can be found here`}</a>{`.`}</p>
    <p>{`Finally, the Uniswap contract code was frozen and RV began work on formally verifying the contracts implementation matched desirable properties of the spec. This was the longest step of the process.`}</p>
    <p>{`While the audit was on-going I hired Callil as a contractor to begin designing a production version of the Uniswap trading interface. The demo interface worked well, but felt too confusing for the finished product.`}</p>
    <h1 {...{
      "id": "recipeint-🤦",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#recipeint-%F0%9F%A4%A6",
        "aria-label": "recipeint 🤦 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RECIPEINT `}<a parentName="h1" {...{
        "href": "https://emojipedia.org/face-palm/"
      }}>{`🤦`}</a></h1>
    <p>{`Uniswap had not yet been announced, but it was not entirely a secret either. In September I was invited to speak on a panel at `}<a parentName="p" {...{
        "href": "https://ethis.io/"
      }}>{`ETHIS`}</a>{`, a blockchain conference in Hong Kong. While technically not my first time speaking at a conference, it was the first time I was invited to do so!`}</p>
    <p><blockquote parentName="p" {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`Had a great time talking about `}<a parentName="p" {...{
            "href": "https://twitter.com/UniswapExchange"
          }}>{`@UniswapExchange`}</a>{` on the Future of Decentralized Exchange panel at `}<a parentName="p" {...{
            "href": "https://twitter.com/hashtag/ETHIS?src=hash&ref_src=twsrc%5Etfw"
          }}>{`#ETHIS`}</a>{` `}<a parentName="p" {...{
            "href": "https://t.co/fGUHsif2h1"
          }}>{`pic.twitter.com/fGUHsif2h1`}</a></p>{`— Hayden Adams 🦄 (@haydenzadams) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/haydenzadams/status/1039008356009168896"
        }}>{`September 10, 2018`}</a></blockquote></p>
    <p>{`In Hong Kong, I was able to catch up with Vitalik, who took a look at my finalized Uniswap’s code. He caught one error that even Runtime Verification had missed. I misspelled “recipient” as “recipeint” about 20 times.`}</p>
    <p>{`I also accidentally stumbled my way into an Ethereum Foundation interview on ETH 2.0, and for the first time became the subject of a meme.`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/2244/1*g2y4lBPZGGqFIvdCvqUDdA.jpeg",
        "alt": "With ETH falling below $200, this type of humor was common in September, 2018"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://i.imgur.com/3A31jrX.jpg"
      }}>{`Here’s a blank template`}</a>{`, if anyone else wants to try.`}</p>
    <p>{`After Hong Kong, I spent 3 days at Shanghai Blockchain week on a pass-through Visa before returning to New York.`}</p>
    <h1 {...{
      "id": "preparing-for-launch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#preparing-for-launch",
        "aria-label": "preparing for launch permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparing for Launch`}</h1>
    <p>{`Back in New York I decided I would launch Uniswap in Prague at `}<a parentName="p" {...{
        "href": "https://devcon4.ethereum.org/index.html"
      }}>{`Devcon 4`}</a>{`, if it was humanly possible. There were just 5 weeks to go — the busiest 5 weeks of my life.`}</p>
    <p>{`With formal verification underway, the biggest outstanding task was implementing Callil’s latest frontend designs on top of a production ready codebase. I put out feelers for a contracting firm that could build this in a month, and was introduced to `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/sdtsui/"
      }}>{`Dan Tsui`}</a>{`, CEO at `}<a parentName="p" {...{
        "href": "https://www.kyokan.io/"
      }}>{`Kyokan`}</a>{`. `}<em parentName="p">{`Thanks `}<a parentName="em" {...{
          "href": "https://twitter.com/AFDudley0"
        }}>{`Rick Dudley`}</a>{` for the intro!`}</em></p>
    <p>{`The codebase was to be re-written almost entirely from scratch. I hired a contracting firm because I believed building a feature complete Uniswap frontend on such a short time frame to the standards I was hoping for required an entire team. Instead I got something better —`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/chikeichan/"
      }}>{` Jacky Chan`}</a>{`, VP of engineering at Kyokan.`}</p>
    <p>{`As Jacky was coming in with almost no context on Uniswap, I wrote `}<a parentName="p" {...{
        "href": "https://hackmd.io/hthz9hXKQmSyXfMbPsut1g"
      }}>{`a long and detailed frontend spec`}</a>{` to help speed along the process, while Callil provided highly detailed designs for every piece of the interface. In one month, Jacky built the Uniswap launch interface, almost entirely by himself. For a few smaller pieces, `}<a parentName="p" {...{
        "href": "https://github.com/kennyt?tab=followers"
      }}>{`Kenny Tram`}</a>{` of Kyokan jumped in to help.`}</p>
    <p>{`About a week before Devcon, I finished the `}<a parentName="p" {...{
        "href": "https://docs.uniswap.io/"
      }}>{`docs`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://hackmd.io/C-DvwDSfSxuh-Gd4WKE_ig"
      }}>{`whitepaper`}</a>{`. Also, `}<a parentName="p" {...{
        "href": "https://github.com/runtimeverification/verified-smart-contracts/tree/uniswap/uniswap/results"
      }}>{`the results of RVs formal verification work`}</a>{` were in. No issues found! However, the Runtime Verification team expressed a concern. Uniswap had not been adequately studied for re-entrancy attacks, the issue behind `}<a parentName="p" {...{
        "href": "https://medium.com/@ogucluturk/the-dao-hack-explained-unfortunate-take-off-of-smart-contracts-2bd8c8db3562"
      }}>{`the DAO hack`}</a>{`. I reached out to Phil Daian for advice.`}</p>
    <p>{`He was extremely busy preparing to `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=i-oWE8hg6-0"
      }}>{`present his latest research at Devcon 4`}</a>{`, but nevertheless promised to look through the smart contracts for re-entrancy attacks before launch. Due to the last minute nature, this would of course be an internal audit — there would be no writeup and the results would not be made public.`}</p>
    <h1 {...{
      "id": "v0-unishirts--devcon-4-limited-edition",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#v0-unishirts--devcon-4-limited-edition",
        "aria-label": "v0 unishirts  devcon 4 limited edition permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`V0 Unishirts — Devcon 4 Limited Edition`}</h1>
    <p>{`In parallel to all of this I contracted Callil to design a shirt for the Uniswap to give out at the conference. I wanted the launch to be a moment. Something people could participate in. The shirt needed to be high quality — a shirt people would actually want to wear.`}</p>
    <p>{`About a week before Devcon, in collaboration with his brother `}<a parentName="p" {...{
        "href": "https://twitter.com/leandercapuozzo"
      }}>{`Leander`}</a>{`, Callil put together this incredibly cool design:`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/4544/1*sEQ0MxegcmmeMp2hjPhwBw.jpeg",
        "alt": "Unishirt Design Mockup"
      }}></img></p>
    <p>{`To be finished on time, the shirts would have to be handmade through a process called silk screening. It would take almost a week to create the screen and about 8 hours to make the shirts.`}</p>
    <p>{`I flew to Prague a day before the conference and began making sure all the pieces were in place. I checked in with Phil — he had reviewed most of the code but wanted to go over it again.`}</p>
    <p>{`Callil bought a flight that landed on the first day of the conference to leave as much time for manufacturing as possible. He pulled an all nighter manufacturing shirts (along with Leander).`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/8064/1*NaFUfl83E8sHPAyX7YrIwA.jpeg",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/6048/1*2iOiPdV639vmg_l2mtMw6A.jpeg",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/3072/1*8Gt3O8JaSPYtSdMoZyQXpg.jpeg",
        "alt": "Manufacturing Unishirts"
      }}></img></p>
    <p>{`As soon as the shirts were finished he boxed them up, went straight to the airport and flew to Prague. He took a taxi from the airport directly to the conference center to hand them out with me.`}</p>
    <h1 {...{
      "id": "devcon-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#devcon-4",
        "aria-label": "devcon 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Devcon 4`}</h1>
    <h4 {...{
      "id": "day-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#day-1",
        "aria-label": "day 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Day 1`}</h4>
    <p>{`Devcon was underway! I wandered the conference center handing out shirts, talking about Uniswap to anyone who listen to me, hinting the launch was happening soon.`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/6048/1*XG_RXxE3ju-M3d6UQBjuQA.jpeg",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/6048/1*Hd0GvmhtuVbAaLJ8_0Lqaw.jpeg",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/6048/1*JCvVDXwtpYqvg-vi2vGDng.jpeg",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/4790/1*C1HiJA1vSPVZVax7sBHzwQ.jpeg",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/8064/1*2gS5f-URgXW8x_8ure0-wg.jpeg",
        "alt": "← Unishirts have math and charts on the back, making them excellent tools for understanding automated market makers"
      }}></img></p>
    <p>{`In the afternoon, Callil and I met Jacky in person for the first time. After some socializing, we got to work fixing outstanding bugs adding UX improvements.`}</p>
    <h4 {...{
      "id": "day-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#day-2",
        "aria-label": "day 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Day 2`}</h4>
    <p>{`I spent half the second day wandering the conference and watching talks. In the afternoon, once more I got together with Jacky and Callil. We had a realization — Uniswap as a project should have a landing page separate from the trading interface. We built `}<a parentName="p" {...{
        "href": "https://uniswap.io/"
      }}>{`uniswap.io`}</a>{` from scratch that day.`}</p>
    <h4 {...{
      "id": "day-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#day-3",
        "aria-label": "day 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Day 3`}</h4>
    <p>{`Finally, on the 3rd day of Devcon I heard back from Phil. As far as he could tell, there was no re-entrancy attacks that could be done on Uniswap exchange contracts, unless the token transfer function was specifically designed to allow re-entrancy into Uniswap.`}</p>
    <p>{`This felt like an acceptable result — if a token was designed to attack Uniswap then the liquidity for that specific token could be stolen. All regular ERC20 tokens were safe from re-entrancy.`}</p>
    <h4 {...{
      "id": "day-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#day-4",
        "aria-label": "day 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h4">{`Day 4`}</strong></h4>
    <p>{`On November 2, 2018 — the final day of Devcon 4 — the smart contracts were deployed to the Ethereum mainet. Next, `}<a parentName="p" {...{
        "href": "https://uniswap.io/"
      }}>{`uniswap.io`}</a>{` and `}<a parentName="p" {...{
        "href": "https://app.uniswap.org/#/swap"
      }}>{`app.uniswap.org/#`}</a>{` were deployed. Finally, I reached out to anyone who had expressed interest in providing liquidity at launch.`}</p>
    <p>{`About $30,000 was deposited into the contracts by a single provider, split across 3 tokens. This allowed for swaps of about $100. There was nothing else left to do.`}</p>
    <p>{`I spent about an hour sitting alone on a bean bag, writing and re-writing the launch tweets. Fortunately, my friend Ashleigh walked by and helped review my tweets.`}</p>
    <p>{`I clicked the button, nervous but excited for what the future would hold. What followed was a huge outpouring of support, ideas, and collaboration beyond my wildest expectation.`}</p>
    <p>{`But that’s a story for V1, the next blogpost in this series :)`}</p>
    <h1 {...{
      "id": "",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#",
        "aria-label": " permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🦄`}</h1>
    <h1 {...{
      "id": "bonus-1--who-named-it-uniswap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#bonus-1--who-named-it-uniswap",
        "aria-label": "bonus 1  who named it uniswap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`BONUS #1 — Who named it Uniswap?`}</h1>
    <p>{`Vitalik did. I was originally going to call it Unipeg — a mixture between a Unicorn and a Pegasus.`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/358/1*zdEbqYZUIX1I2HDflumEwA.png",
        "alt": "Unipeg Logo"
      }}></img></p>
    <p>{`When Karl first told Vitalik about the project he said:`}</p>
    <h3 {...{
      "id": "unipeg-it-sounds-more-like-a-uniswap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#unipeg-it-sounds-more-like-a-uniswap",
        "aria-label": "unipeg it sounds more like a uniswap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`“Unipeg? it sounds more like a Uniswap”`}</h3>
    <h1 {...{
      "id": "bonus-2--who-invented-uniswap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#bonus-2--who-invented-uniswap",
        "aria-label": "bonus 2  who invented uniswap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`BONUS #2 — Who invented Uniswap?`}</h1>
    <p>{`Alan Lu of Gnosis was the very first person to conceive of x`}{`*`}{`y=k market makers on Ethereum`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://blog.gnosis.pm/building-a-decentralized-exchange-in-ethereum-eea4e7452d6e"
        }}><strong parentName="a">{`Building a Decentralized Exchange in Ethereum`}</strong></a></p>
      <small></small>
    </blockquote>
    <p>{`Martin Koppleman of Gnosis told Vitalik about the idea.`}</p>
    <p>{`Vitalik saw its potential and began posting about it publicly. On his website:`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://vitalik.ca/general/2017/06/22/marketmakers.html"
        }}><strong parentName="a">{`On Path Independence`}</strong></a></p>
      <small>Suppose that someone walks up to you and starts exclaiming to you that he thinks he has figured out how to create a source of unlimited free energy. His scheme looks as follows. First, you get a spaceship up to low Earth orbit.</small>
    </blockquote>
    <p>{`On reddit:`}</p>
    <blockquote className="reddit-card" data-card-created="1584970862"><a href="https://www.reddit.com/r/ethereum/comments/55m04x/lets_run_onchain_decentralized_exchanges_the_way/">Let's run on-chain decentralized exchanges the way we run prediction markets</a> from <a href="http://www.reddit.com/r/ethereum">r/ethereum</a></blockquote>
    <script async src="//embed.redditmedia.com/widgets/platform.js" charSet="UTF-8"></script>
    <p>{`and in the comments of this article written by Phil Daian:`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "http://hackingdistributed.com/2017/06/19/bancor-is-flawed/#comment-3370835720"
        }}><strong parentName="a">{`Bancor Is Flawed`}</strong></a></p>
      <small>Bancor just did their Initial Coin Offering (ICO) last week and raised a record \$144M within a few hours. They now hold the record for the biggest crowd-funding, ever, in the history of mankind. We don't want to dwell too much on what this illustrates about the current ICO craze.</small>
    </blockquote>
    <p>{`I created Uniswap, the specific implementation of an x`}{`*`}{`y=k market maker that exists on Ethereum today.`}</p>
    <small>Thanks to Jinglan Wang.</small>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      